import { type Page } from '~/types/pages'
// import DashboardOperator from '~/components/dashboard/DashboardOperator'
import { useMount } from 'react-use'
import { useRouter } from 'next/router'

const DashboardPage: Page = ({}) => {
  // const RenderDashboard = () => {
  //   return <DashboardOperator />
  // }
  //
  // return (
  //   <div className="container py-32 px-24">
  //     <RenderDashboard />
  //   </div>
  // )

  const router = useRouter()

  const redirect = async () => {
    await router.push(`/projects`, undefined, { shallow: true })
  }

  useMount(redirect)

  return null
}

DashboardPage.layout = 'dashboard'
DashboardPage.middleware = 'auth'
DashboardPage.title = 'Dashboard'

export default DashboardPage
